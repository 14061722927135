<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class="hover"><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span class="import">商品名称：</span><input type="text" v-model="form.name"/></li>
            <li class="up"><span class="import">分配门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName" :disabled="this.$store.state.User.jibieId!=0"> 
                <option :value="0">全部门店</option>
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
            <li class="up"><span>商品型号：</span><input type="text" v-model="form.model"/></li>
            <li class="up"><span>商品商标：</span><input type="text" v-model="form.brand"/></li>
            <li class="up"><span>商品系列：</span><input type="text" v-model="form.trademark"/></li>
            <li class="up"><span class="import">包装单位：</span>
              <select v-model="form.unit"> 
                <option :value="item" v-for="item in unitList" :key="item">{{item}}</option>
              </select>
            </li>
            <li class="up"><span class="import">成本价格：</span><input type="number" v-model="form.chengben"/> 元</li>
            <li class="up"><span class="import">出售价格：</span><input type="number" v-model="form.chushoujiage"/> 元</li>
            <li class="up"><span class="import">会员优惠：</span>
              <select v-model="form.isDis"> 
                <option :value="0">不允许</option>
                <option :value="1">允许</option>
              </select>
            </li>
            <li class="up"><span class="import">提成比例：</span><input type="number" v-model="form.tichengbaifenbi" :disabled="$store.state.User.daoqishijian<0"/> % 
              <select v-model="form.baifenbifangshi" :disabled="$store.state.User.daoqishijian<0"> 
                <option value="0">售价百分比</option>
                <option value="1">售价减成本后百分比</option>
              </select>
              <img src="@/assets/images/vip.png">
              </li>
            <li class="up"><span class="import">固定提成：</span><input type="number" v-model="form.tichengguding" :disabled="$store.state.User.daoqishijian<0"/> 元
            <img src="@/assets/images/vip.png"></li>
            <li class="up"><span>生产企业：</span><input type="text" v-model="form.shengchanqiye"/></li>
            <li class="up"><span>进货渠道：</span><input type="text" v-model="form.jinhuoqudao"/></li>
            <li class="up"><span>备注信息：</span><textarea v-model="form.memo"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {productUnit} from "@/data/data"
import {testStr,deepCopy} from '@/utils/util'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
        }else{
          this.form=deepCopy(newD.data)
        }
      },
      deep:true
    }
  },
  data() {
    return {
      unitList:productUnit,
      storeList:[],
      form:{
        name:'',
        model:'',
        brand:'',
        trademark:'',
        unit:'瓶',
        chengben:0,
        chushoujiage:'',
        isDis:0,
        tichengbaifenbi:0,
        baifenbifangshi:0,
        tichengguding:0,
        shengchanqiye:'',
        jinhuoqudao:'',
        fenpeimendianId:0,
        memo:'',
        fenpeimendianName:'全部门店',
      },
      defform:{
        name:'',
        model:'',
        brand:'',
        trademark:'',
        unit:'瓶',
        chengben:0,
        chushoujiage:'',
        isDis:0,
        tichengbaifenbi:0,
        baifenbifangshi:0,
        tichengguding:0,
        shengchanqiye:'',
        jinhuoqudao:'',
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
        memo:'',
        fenpeimendianName:this.$store.state.User.jibieId==0?"全部门店":this.$store.state.User.fenpeimendianName,
      }
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    init(){
      if(this.$store.state.User.jibieId==0) this.getStore()
      if(this.MmsData.new){
        this.form=deepCopy(this.defform)
      }else{
        this.form=deepCopy(this.MmsData.data)
      }
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
      else this.form.fenpeimendianName='全部门店'
    },
    subData(){
      if(testStr(this.form.name)){
        this.$Mesg({
          content: '商品名称不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.unit)){
        this.$Mesg({
          content: '单位不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.chushoujiage)){
        this.$Mesg({
          content: '出售价格不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      axios.post(this.MmsData.new?'/product/add':'/product/edit',this.form).then((res) => {
        if(res.code===5000){
         this.$Mesg({content: this.MmsData.new?'新增商品成功！':'修改商品成功！'})
          this.closedWindow()
          this.$parent.getData(1)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
  },
}
</script>
