<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option :value="0">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>商品名：</p>
          <input type="text" v-model="form.name" @keypress.enter="getData(1)">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="drags_out">
        <table class="drags_3">
          <thead>
            <tr>
              <th>商品</th>
              <th>单位</th>
              <th>成本价格 | 出售金额</th>
              <th>会员优惠</th>
              <th>生产企业 | 进货渠道</th>
              <th>分配门店</th>
              <th>提成百分比</th>
              <th>固定提成</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dataList" :key="item.id">
              <td>
                <p><span class="blue">{{item.name}}</span><template v-if="item.model"> | </template>{{item.model}}</p>
                <p>{{item.brand}}<template v-if="item.trademark"> | </template>{{item.trademark}} </p>
              </td>
              <td>
                <p>{{item.unit}}</p>
              </td>
              <td>
                <p>{{item.chengben}}元/{{item.unit}}</p>
                <p>{{item.chushoujiage}}元/{{item.unit}}</p>
              </td>
              <td>{{item.isDis?'允许':'不允许'}}</td>
              <td>
                <p>{{item.shengchanqiye}}</p>
                <p>{{item.jinhuoqudao}}</p>
              </td>
              <td>
                <p>{{item.fenpeimendianName}}</p>
              </td>
              <td>
                <p>{{item.baifenbifangshi==1?'售价减成本后':''}}{{item.tichengbaifenbi}}%</p>
              </td>
              <td>
                <p>{{item.tichengguding}}元/{{item.unit}}</p>
              </td>
              <td>
                <div class="buttonGroup">
                  <button type="button" class="suc" v-if="countPow(item.fenpeimendianId)" @click="setInfoData(item)" v-pow:2202>管理</button>
                  <button type="button" class="err" v-if="countPow(item.fenpeimendianId)" @click="delOneData(item.id,item.name)" v-pow:2203>删除</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newProductAdd" v-pow:2201>新增商品</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <Info :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></Info>
  </div>
</template>

<script>
import Info from './info.vue'
import axios from '@/http/axios'
import { changeStr } from "@/utils/util";
export default {
  name: 'storeManagement',
  data() {
    return {
      title: '',
      infoShow: false,
      storeList:[],
      dataList:[],
      form:{
        name:'',
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
    this.getData()
    if(this.$store.state.User.jibieId==0) this.getStore()
  },
  computed:{
    countPow(){
      return (fenpeimendianId)=>{
        if(this.$store.state.User.jibieId==0) return true
        if(this.$store.state.User.fenpeimendianId==fenpeimendianId) return true
        else return false
      }
    }
  },
  components: {Info},
  mounted() {
  },
  methods: {
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认删除 ${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/product/del',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    setInfoData(item){
      this.title=item.name
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    Str(str){
      if(str) return changeStr(str)
      return '无'
    },
    newProductAdd(){
      this.title="新增商品"
      this.MmsData.new=true
      this.infoShow=true
    },
    closedWindow(){
      this.infoShow=false
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/product/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }
        else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    }

  },
}
</script>

